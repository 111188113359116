import React from "react";
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";

import { BsLinkedin } from "react-icons/bs";
import {
  MdEmail,
  MdLocationCity,
  MdCalendarMonth,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";

import myImage from "../assets/images/me.png";
import defaultProjectImage from "../assets/images/default.png";
import carFinanceImage from "../assets/images/car.jpg";
import idProcessingImage from "../assets/images/id-card.jpg";
import kycImage from "../assets/images/kyc.jpg";
import nextWordImage from "../assets/images/next-word.jpg";
import searchImage from "../assets/images/search.jpg";
import speechImage from "../assets/images/speech.jpg";
import ocrImage from "../assets/images/ocr.jpg";

function Section(props: {
  children: React.ReactNode;
  gray?: boolean;
  className?: string;
}) {
  return (
    <div className={props.gray ? "section gray" : "section"}>
      <Container className={props.className}>{props.children}</Container>
    </div>
  );
}

function Connect(props: {
  link: string;
  text: string;
  icon: React.ReactElement;
}) {
  return (
    <a href={props.link} className="connect" target="_blank" rel="noreferrer">
      {props.icon}
      <span>{props.text}</span>
    </a>
  );
}

function Career(props: {
  company: string;
  location: string;
  position: string;
  role: string;
  duration: string;
}) {
  return (
    <div className="career">
      <div className="position">
        <div className="title">{props.company}</div>
        <div className="position">{props.position}</div>
        <div>
          <div className="d-md-inline-block duration">
            <MdCalendarMonth />
            {props.duration}
          </div>
          <div className="d-md-inline-block ms-md-2 location">
            <MdLocationCity />
            {props.location}
          </div>
        </div>
        <div className="role">{props.role}</div>
      </div>
    </div>
  );
}

function Project(props: {
  title: string;
  text: string;
  image: string;
  skills: string[];
}) {
  return (
    <div className="d-md-flex project">
      <div className="mb-3 me-md-3 mb-md-0 image">
        <img src={props.image} alt="project" />
      </div>
      <div className="content">
        <h5>{props.title}</h5>
        <p>{props.text}</p>
        <ul>
          {props.skills.map((text, key) => (
            <li key={key}>{text}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function App() {
  const [showMoreProjects, setShowMoreProjects] = React.useState(false);

  return (
    <div>
      <Section className="d-md-flex">
        <div className="order-md-2 align-items-center profile-picture">
          <img src={myImage} alt="Me" />
        </div>
        <div className="order-md-1 d-flex align-items-center flex-1">
          <div>
            <h3>Muhammad Raza</h3>
            <p>
              I'm a passionate ML Engineer and Researcher with a strong
              background in Computer Vision and NLP. I am currently working at
              FetchSky.
            </p>
            <Connect
              text="linkedin.com/in/mraza2004"
              link="https://www.linkedin.com/in/mraza2004"
              icon={<BsLinkedin />}
            />
            <Connect
              text="mraza@itu.edu.pk"
              link="mailto:mraza@itu.edu.pk"
              icon={<MdEmail />}
            />
            <Connect
              text="raza.soft@hotmail.com"
              link="mailto:raza.soft@hotmail.com"
              icon={<MdEmail />}
            />
          </div>
        </div>
      </Section>
      <Section gray>
        <h4>My Projects</h4>
        <Project
          image={kycImage}
          title="Know-Your-Customer Solution"
          text={
            "Crafted a Pakistan-specific KYC solution for banking clients that can " +
            "accurately extract information from ID document images, " +
            "verify face images using deep face recognition, and ensure user liveliness " +
            "by providing real-time facial gesture instructions and monitoring user responses."
          }
          skills={["PyTorch", "Python", "Docker", "Computer Vision"]}
        />
        <Project
          image={searchImage}
          title="Intelligent Search Engine"
          text={
            "Implemented a search engine that can retrieve pertinent information, " +
            "generate concise search summaries, and present data through user-friendly " +
            "interfaces using such as maps, list, grids etc. its query understanding. " +
            "The system can also utilize LLMs to provide conversation experience."
          }
          skills={["PyTorch", "Python", "NLP"]}
        />
        <Project
          image={carFinanceImage}
          title="Automatic Vehicle Recognition"
          text={
            "Developed an automated car recognition system that identifies a vehicle's " +
            "make and model from images using image classification, capable of " +
            "automatically collecting data from public domain and retrain itself to stay " +
            "up to date. It's currently in use by a bank to enhance the car finance " +
            "inquiry and on-boarding process."
          }
          skills={["PyTorch", "Python", "GCP", "Computer Vision"]}
        />
        <Collapse in={showMoreProjects}>
          <div>
            <Project
              image={idProcessingImage}
              title="ID Card Image Processing"
              text={
                "Developed a computer vision based system capable of detecting ID card, correcting " +
                "image perspective, and extracting bilingual text information. " +
                "Additionally, I developed a lightweight deep learning model for real-time " +
                "image verification to improve front-end user experience."
              }
              skills={["PyTorch", "Python", "GCP", "Computer Vision"]}
            />
            <Project
              image={ocrImage}
              title="Urdu text recognition"
              text={
                "Achieved less than 3% Character Error Rate (CER) with a text recognition " +
                "model supporting both Urdu and English, trained solely on synthetic data."
              }
              skills={["PyTorch", "Python", "GCP", "Computer Vision"]}
            />
            <Project
              image={speechImage}
              title="Speech Keyword Spotting"
              text={
                "This was a research project focused on developing a speech keyword spotting " +
                "system tailored for low-resource languages such as Urdu."
              }
              skills={["PyTorch", "Python", "GCP", "Computer Vision"]}
            />
            <Project
              image={nextWordImage}
              title="Rekhta"
              text={
                "Created a light-weight intelligent next word prediction system that achieved " +
                "more than 50% keystorke saving rate. Developed a browser extension, along " +
                "with mobile and desktop applications, for data collection and model testing."
              }
              skills={["PyTorch", "Python", "GCP", "Computer Vision"]}
            />
          </div>
        </Collapse>
        <div
          className="show-more"
          onClick={() => setShowMoreProjects(!showMoreProjects)}
        >
          <span className="me-1">{showMoreProjects ? "Less" : "More"}</span>{" "}
          {showMoreProjects ? <MdExpandLess /> : <MdExpandMore></MdExpandMore>}
        </div>
      </Section>
      <Section>
        <h4>Career</h4>
        <Career
          company="FetchSky"
          position="ML Engineer (Part-time)"
          duration="June 2021 (Ongoing)"
          location="Karachi, Pakistan"
          role={
            "My role is mainly to design, develop and deploy ML solutions for customers" +
            " from different industries."
          }
        />
        <Career
          company="Center for Speech and Language Technologies "
          position="Research Assistant"
          duration="2018-2019"
          location="Lahore, Pakistan"
          role={
            "Under supervision for Dr. Agha Ali Raza, I conducted research on various " +
            "NLP and Speech Processing projects, with a specific focus on low-resource " +
            "languages, such as Urdu."
          }
        />
        <a href="https://mraza.dev/resume.pdf">My Resume</a>
      </Section>
    </div>
  );
}

export default App;
